import React from 'react';
import { FormattedMessage } from 'react-intl';

import Link from '/src/components/LocalizedLink';
import Layout from '../../components/Layout';
import Faq from '../../components/Faq';

import useLocale from '../../hooks/useLocale';

import imgRmain from '../../assets/images/how-to-make-a-good-application/Photo 2 (6).png';
import imgDots from '../../assets/images/application-process/Dots 3.svg';
import IconArrowRightRed from '../../assets/images/svg/arrow-right-red.inline.svg';
import IconWhatsApp from '../../assets/images/svg/whatsapp.inline.svg';

import '../../assets/styles/pages/how-to-make-a-good-application-page.scss';

const faqs = [
  {
    id: 1,
    title: 'How can I make my application stand out?',
    description: `
    A great application is one that shows a <strong>good level of language awareness</strong> in the task and the candidate displays a <strong>great attitude towards teaching and learning</strong> during the interview.
                    <span>We love to receive applications from people that are:</span>
                    <ul class='faq-list'>
                        <li class='faq-list__item'>Passionate about teaching and learning</li>
                        <li class='faq-list__item'>Excited about studying abroad</li>
                        <li class='faq-list__item'>Are open to learning new ideas</li>
                        <li class='faq-list__item'>Are willing to learn and take on constructive feedback</li>
                        <li class='faq-list__item'>Have the stamina and appetite for one month of intensive study</li>
                    </ul>
                    <p class="mt-15">If you tick all of these boxes, we want to hear from you! Chances are you’ll be a great teacher and we’d love to work with you.</p> 
    `,
  },
  {
    id: 2,
    title: 'Why do I have to do an interview, can’t I just sign up?',
    description: `
    The Trinity CertTESOL course is as demanding as the first year of an undergraduate degree so we have to ascertain that you can deal with the academic demands and time pressure of the course. We also have to confirm that your level of speaking, reading, writing and listening are sufficiently high in order to teach English.
    <br/><br/>
    The course involves one month of intensive studying and is not suited to everyone, it is best find out your suitability for English teaching before you have invested in the course.
    `,
  },
  {
    id: 3,
    title: 'Why do you test my language / grammar awareness before the course?',
    description: `
    Applicants are sometimes surprised at being asked to display grammar knowledge before the course even starts, as they feel this is part of the reason for attending the course in the first place. In truth, the grammar questions before the course are not aimed at testing your inherent ability, but your willingness to research the answers and find out more. Before the course we are looking for <strong>potential</strong>.
    <br/><br/>
    Also, working your way through grammar exercises will <strong>give you a taste of the course material</strong>, and for some, might be sufficient to convince them that teaching English is just not for them after all. It is better to find out now rather than halfway through the course!
    `,
  },
  {
    id: 4,
    title: 'I don’t have a university degree, can I still do the course?',
    description:
      'Yes, in order to be accepted onto the Trinity CertTESOL course you need to demonstrate sufficient qualifications / work experience to be accepted onto an undergraduate degree course, but a degree itself is not essential.',
  },
  {
    id: 5,
    title: 'English isn’t my first language, is that a problem?',
    description: `
    No, to do the course you have to demonstrate a high level of competence in reading, writing, 
    speaking and listening in English as appropriate to a teacher of the language. If English 
    is not your first language you should have English that is at C1 level of the Common European 
    Framework of Reference (CEFR) in all of the four skills: reading, writing, speaking and listening.
    <br/><br/>
    If you have any more questions about the application process please feel free to contact our coordination 
    team at coordination@tefl-iberia.com.`,
  },
];

const faqsEs = [
  {
    id: 1,
    title: '¿Cómo puedo destacar en mi solicitud?',
    description: `
    Una excelente solicitud es aquella que demuestra un buen nivel de conciencia lingüística en el quiz y el candidato muestra una gran actitud hacia la enseñanza y el aprendizaje durante la entrevista. 
                    <span>Nos encanta recibir solicitudes de personas que: </span>
                    <ul class='faq-list'>
                        <li class='faq-list__item'>Son apasionadas por la enseñanza y el aprendizaje.</li>
                        <li class='faq-list__item'>Están emocionadas por estudiar en el extranjero.</li>
                        <li class='faq-list__item'>Están abiertas a aprender nuevas ideas.</li>
                        <li class='faq-list__item'>Están dispuestas a aprender y aceptar críticas constructivas.</li>
                        <li class='faq-list__item'>Tienen disposición y capacidad de resistir un mes de estudio intensivo</li>
                    </ul>
                    <p class="mt-15">Si cumples con todos estos requisitos, ¡queremos saber de ti! Es probable que seas un gran profesor y nos encantaría trabajar contigo.</p> 
    `,
  },
  {
    id: 2,
    title: '¿Por qué debo hacer una entrevista? ¿No puedo simplemente inscribirme?',
    description: `
    El curso Trinity CertTESOL es tan exigente como el primer año de una carrera universitaria, por lo que debemos asegurarnos de que puedas manejar las demandas académicas y la presión temporal del curso. También debemos confirmar que tu nivel de expresión oral, lectura, escritura y comprensión auditiva sea lo suficientemente alto como para enseñar inglés.
    <br/><br/>
    El curso implica un mes de estudio intensivo y no es adecuado para todos. Lo mejor es determinar tu aptitud para la enseñanza del inglés antes de invertir en el curso.
    `,
  },
  {
    id: 3,
    title: '¿Por qué evalúan mi conocimiento del idioma y la gramática antes del curso?',
    description: `
    A veces, los solicitantes se sorprenden de que se les pida mostrar conocimientos de gramática antes de que el curso comience, ya que sienten que esa es precisamente la razón por la que asisten al curso en primer lugar. En realidad, las preguntas de gramática antes del curso no tienen como objetivo evaluar tu capacidad innata, sino tu disposición para investigar las respuestas y aprender más. Antes del curso, estamos buscando <strong>potencial</strong>.
    <br/><br/>
    Además, resolver ejercicios de gramática te dará una idea del material del curso y, para algunos, podría ser suficiente para convencerse de que la enseñanza del inglés no es lo suyo después de todo. ¡Es mejor descubrirlo ahora que a mitad del curso!
    `,
  },
  {
    id: 4,
    title: 'No tengo un título universitario, ¿puedo hacer el curso de todos modos?',
    description:
      'Sí, para ser aceptado en el curso Trinity CertTESOL, debes demostrar cualificaciones / experiencia laboral suficiente pero el título en sí no es esencial.',
  },
  {
    id: 5,
    title: 'El inglés no es mi primer idioma, ¿es eso un problema?',
    description: `
    No, pero para hacer el curso debes demostrar un alto nivel de competencia. Si el inglés no es tu primer idioma, debes tener un nivel de inglés equivalente al nivel C1 del Marco Común Europeo de Referencia (MCER) en las cuatro habilidades: lectura, escritura, expresión oral y comprensión auditiva.
    <br/><br/>
    Si tienes más preguntas sobre el proceso de solicitud, no dudes en contactar a nuestro equipo de coordinación en coordination1@tefl-iberia.com.`,
  },
];

const HowToMakeaGoodApplication = () => {
  const { enLocale } = useLocale({ matchPathName: '/es/' });

  return (
    <Layout
      title={{ id: 'howToMakeGoodApp.seo.title' }}
      description={{ id: 'howToMakeGoodApp.seo.description' }}
    >
      <div className="how-to-make-a-good-application-page">
        <section className="c-first-section c-first-section--md">
          <div className="container">
            <h1 className="title">
              <FormattedMessage id="howToMakeGoodApp.sec1.title" />
            </h1>
          </div>
        </section>
        <section className="second-section">
          <div className="c-application-process-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-7 col-12">
                  <div className="left">
                    <h1 className="c-title-34">
                      <FormattedMessage id="howToMakeGoodApp.sec2.title" />
                    </h1>
                    <div className="left__wrapper">
                      <div className="circle">
                        <span>1</span>
                      </div>
                      <div className="left__text">
                        <h3 className="c-title-18">
                          <FormattedMessage id="howToMakeGoodApp.sec2.ben1" />
                        </h3>
                      </div>
                    </div>
                    <div className="left__wrapper">
                      <div className="circle">
                        <span>2</span>
                      </div>

                      <div className="left__text">
                        <h3 className="c-title-18">
                          <FormattedMessage id="howToMakeGoodApp.sec2.ben2" />
                        </h3>
                      </div>
                    </div>
                    <div className="left__wrapper">
                      <div className="circle">
                        <span>3</span>
                      </div>
                      <div className="left__text">
                        <h3 className="c-title-18">
                          <FormattedMessage id="howToMakeGoodApp.sec2.ben3" />
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-5 col-12">
                  <div className="right">
                    <img
                      src={imgRmain}
                      alt="How To Make A Good Application"
                      className="right__img"
                    />
                    <img src={imgDots} alt="dots" className="dots" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="third-section c-faqs-section">
          <div className="container">
            <div className="c-faqs-section__title">
              <h2 className="c-title-34">
                <FormattedMessage id="common.faqs" />
              </h2>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Faq
                  description={enLocale ? faqs[0].description : faqsEs[0].description}
                  title={enLocale ? faqs[0].title : faqsEs[0].title}
                />
                <Faq
                  description={enLocale ? faqs[1].description : faqsEs[1].description}
                  title={enLocale ? faqs[1].title : faqsEs[1].title}
                />
                <Faq
                  description={enLocale ? faqs[2].description : faqsEs[2].description}
                  title={enLocale ? faqs[2].title : faqsEs[2].title}
                />
              </div>
              <div className="col-lg-6">
                <Faq
                  description={enLocale ? faqs[3].description : faqsEs[3].description}
                  title={enLocale ? faqs[3].title : faqsEs[3].title}
                />
                <Faq
                  description={enLocale ? faqs[4].description : faqsEs[4].description}
                  title={enLocale ? faqs[4].title : faqsEs[4].title}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default HowToMakeaGoodApplication;
